<template>
    <v-dialog
            v-model="viewDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
    >
        <v-card>
            <v-card-title class="success white--text">{{ detailTitle }}</v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col class="d-flex flex-row align-items-center">
                        <div class="d-flex flex-column mt-3">
                            <span>FS - {{ $t('message.freshStock') }}</span>
                            <span>OS - {{ $t('message.oldStock') }}</span>
                        </div>
                        <v-spacer/>
                        <ExportTableJson
                            :export-data="{
                                stocks: stocks,
                                title: detailTitle
                            }"
                            :export-fields="headers"
                            :export-source="'stocks-sales-weekly-detail'"
                            class="pt-0"
                        />
                    </v-col>
                </v-row>
                <v-overlay
                    :value="loading"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-data-table
                    :fixed-header="stocks.length > 10"
                    :headers="headers"
                    :height="stocks.length > 10 ? tableHeight : null"
                    :items="stocks"
                    :items-per-page="-1"
                    :sort-by="'Stock.age'"
                    calculate-widths
                    class="mt-3 appic-table-light specification-table"
                    dense
                    hide-default-footer
                    id="stocksTable"
                    item-key="Stock.id"
                >
                    <template v-slot:item.Stock.age="{ item }">
                        <div class="text-end font-sm" v-if="item.Stock.age < 180">{{ item.Stock.age }}</div>
                        <div class="text-end font-sm red--text text--darken-3" v-else>180+</div>
                    </template>
                    <template v-slot:item.Stock.freshVolume="{ item }">
                        <div class="text-end mr-3">{{ item.Stock.freshVolume }}</div>
                    </template>
                    <template v-slot:item.Stock.freshValue="{ item }">
                        <div class="text-end mr-3">{{ numberFormat(item.Stock.freshValue, '0,0') }}</div>
                    </template>
                    <template v-slot:item.Stock.freshMargin="{ item }">
                        <div class="text-end mr-3">{{ numberFormat(item.Stock.freshMargin, '0,0') }}</div>
                    </template>
                    <template v-slot:item.Stock.freshMarginPct="{ item }">
                        <div class="text-end mr-3">{{ numberFormat(item.Stock.freshMarginPct, '0,0.0') }}</div>
                    </template>
                    <template v-slot:item.Stock.oldVolume="{ item }">
                        <div class="text-end mr-3">{{ item.Stock.oldVolume }}</div>
                    </template>
                    <template v-slot:item.Stock.oldValue="{ item }">
                        <div class="text-end mr-3">{{ numberFormat(item.Stock.oldValue, '0,0') }}</div>
                    </template>
                    <template v-slot:item.Stock.oldMargin="{ item }">
                        <div class="text-end mr-3">{{ numberFormat(item.Stock.oldMargin, '0,0') }}</div>
                    </template>
                    <template v-slot:item.Stock.oldMarginPct="{ item }">
                        <div class="text-end mr-3">{{ numberFormat(item.Stock.oldMarginPct, '0,0.0') }}</div>
                    </template>
<!--                    <template v-slot:footer>-->
<!--                        <v-simple-table>-->
<!--                            <template v-slot:default>-->
<!--                                <tfoot>-->
<!--                                <tr class>-->
<!--                                    <td class="text-end font-weight-bold" colspan="8">{{ $t('message.total') }}</td>-->
<!--                                    <td class="text-end font-weight-bold" style="width: 50px !important;">{{ totals.currency }}</td>-->
<!--                                    <td class="text-end font-weight-bold" style="width: 85px !important;">-->
<!--                                        <span class="mr-3">{{ numberFormat(0, '0,0') }}</span>-->
<!--                                    </td>-->
<!--                                    <td class="text-end font-weight-bold" style="width: 85px !important;">-->
<!--                                        <span class="mr-3">{{ numberFormat(0, '0,0') }}</span>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                </tfoot>-->
<!--                            </template>-->
<!--                        </v-simple-table>-->
<!--                    </template>-->
                </v-data-table>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" @click="viewDialog = false" >{{$t('message.dismiss')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {capitalize, numberFormat} from "Helpers/helpers";
import {mapGetters} from "vuex";
import {api} from "Api";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "WeeklyStocksDetail",
    props: ['detailTitle','dialog','filters'],
    components: {ExportTableJson},
    data(){
        return {
            loading: false,
            stocks: [],
            totals: {
                currency: 'USD'
            },
            tableHeight: '400',
            view_dialog: false
        }
    },
    computed: {
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        headers() {
            return [
                {
                    id: 1,
                    text: capitalize(this.$t('message.age')),
                    value: 'Stock.age',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 2,
                    text: capitalize(this.$t('message.invoice')),
                    value: 'Stock.invoice',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: capitalize(this.$t('message.buyer')),
                    value: 'Stock.buyer',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.product'),
                    value: 'Stock.product',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.species'),
                    value: 'Stock.species',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.claim'),
                    value: 'Stock.certification',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.bundleNo'),
                    value: 'Stock.bundleNo',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.grade'),
                    value: 'Stock.grade',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: 'FS ' + this.$t('message.qty'),
                    value: 'Stock.freshVolume',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: 'FS ' + this.$t('message.unit'),
                    value: 'Stock.uofmVolume',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: 'FS ' + this.$t('message.value'),
                    value: 'Stock.freshValue',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: 'FS ' + this.$t('message.margin'),
                    value: 'Stock.freshMargin',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: 'FS ' + this.$t('message.marginPct'),
                    value: 'Stock.freshMarginPct',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: 'OS ' + this.$t('message.qty'),
                    value: 'Stock.oldVolume',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 15,
                    text: 'OS ' + this.$t('message.unit'),
                    value: 'Stock.uofmVolume',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 16,
                    text: 'OS ' + this.$t('message.value'),
                    value: 'Stock.oldValue',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 17,
                    text: 'OS ' + this.$t('message.margin'),
                    value: 'Stock.oldMargin',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 18,
                    text: 'OS ' + this.$t('message.marginPct'),
                    value: 'Stock.oldMarginPct',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                }
            ]
        },
        viewDialog: {
            get() {
                return this.view_dialog;
            },
            set(value){
                this.view_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        loadList() {
            this.loading = true;
            api
                .get('/reports/stocks/weekly-sales-detail', {
                    params: {
                        conditions: this.filters
                    }
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.stocks = response.data.data.stocks;
                        // this.totals = response.data.data.totals
                    } else {
                        this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading = false;
                })
        },
        numberFormat
    },
    watch: {
        dialog(value) {
            this.view_dialog = value
            if(value) {
                this.stocks = [];
                this.loadList()
            }
        }
    },
}

</script>

<style scoped>
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep .text-end {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep .v-data-table__wrapper {
    overflow-x: auto;
}
.v-data-table::v-deep  thead > tr > th:nth-child(1) {
    min-width: unset !important;
    background-color: #c5e1a5;
    width: 1px !important;
}
.v-data-table::v-deep .v-data-table-header th {
    white-space: nowrap;
}
</style>