var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","overlay":"false","scrollable":""},model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"success white--text"},[_vm._v(_vm._s(_vm.detailTitle))]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row align-items-center"},[_c('div',{staticClass:"d-flex flex-column mt-3"},[_c('span',[_vm._v("FS - "+_vm._s(_vm.$t('message.freshStock')))]),_c('span',[_vm._v("OS - "+_vm._s(_vm.$t('message.oldStock')))])]),_c('v-spacer'),_c('ExportTableJson',{staticClass:"pt-0",attrs:{"export-data":{
                                stocks: _vm.stocks,
                                title: _vm.detailTitle
                            },"export-fields":_vm.headers,"export-source":'stocks-sales-weekly-detail'}})],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table",attrs:{"fixed-header":_vm.stocks.length > 10,"headers":_vm.headers,"height":_vm.stocks.length > 10 ? _vm.tableHeight : null,"items":_vm.stocks,"items-per-page":-1,"sort-by":'Stock.age',"calculate-widths":"","dense":"","hide-default-footer":"","id":"stocksTable","item-key":"Stock.id"},scopedSlots:_vm._u([{key:"item.Stock.age",fn:function(ref){
                            var item = ref.item;
return [(item.Stock.age < 180)?_c('div',{staticClass:"text-end font-sm"},[_vm._v(_vm._s(item.Stock.age))]):_c('div',{staticClass:"text-end font-sm red--text text--darken-3"},[_vm._v("180+")])]}},{key:"item.Stock.freshVolume",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-end mr-3"},[_vm._v(_vm._s(item.Stock.freshVolume))])]}},{key:"item.Stock.freshValue",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-end mr-3"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.freshValue, '0,0')))])]}},{key:"item.Stock.freshMargin",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-end mr-3"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.freshMargin, '0,0')))])]}},{key:"item.Stock.freshMarginPct",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-end mr-3"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.freshMarginPct, '0,0.0')))])]}},{key:"item.Stock.oldVolume",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-end mr-3"},[_vm._v(_vm._s(item.Stock.oldVolume))])]}},{key:"item.Stock.oldValue",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-end mr-3"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.oldValue, '0,0')))])]}},{key:"item.Stock.oldMargin",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-end mr-3"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.oldMargin, '0,0')))])]}},{key:"item.Stock.oldMarginPct",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-end mr-3"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.oldMarginPct, '0,0.0')))])]}}])})],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){_vm.viewDialog = false}}},[_vm._v(_vm._s(_vm.$t('message.dismiss')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }